@import "/assets/fonts/fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a[href].stears-link {
    @apply text-accent-600;
  }

  a[href] {
    @apply text-accent-600 dark:text-[#85BEFF];
    @apply hover:underline;
  }

  a[href^="http"]::after {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 0.25em;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 10" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.46154 9.99981H0.769231C0.344396 9.99981 0 9.65542 0 9.23058V1.53827C0 1.11344 0.344396 0.769043 0.769231 0.769043H4.23077V1.53827H0.769231V9.23058H8.46154V5.76904H9.23077V9.23058C9.23077 9.65542 8.88637 9.99981 8.46154 9.99981ZM6.53847 0V0.769231H8.68847L5.38462 4.07308L5.92693 4.61538L9.23078 1.31154V3.46154H10V0H6.53847Z" fill="%2385BEFF"/></svg>');
  }

  a[href^="http"].force-as-internal-link::after {
    width: unset;
    height: unset;
    margin-left: 0;
    background-size: unset;
    background-repeat: unset;
    background-image: unset;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .border-spacing-0 {
    border-spacing: 0;
  }

  .text-shadow-white {
    color: black;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
      1px 1px 0 #fff;
  }

  .content-wrapper {
    @apply max-w-[1024px] mx-auto;
  }

  .article-body {
    @apply font-jakarta md:text-lg mb-12;
  }

  .article-body
    > :is(p, h1, h2, h3, h4, h5, h6, ul, ol, figure, div:not(.flourish-embed)) {
    @apply -md:mx-5 max-w-[700px] mx-auto;
  }

  .article-body > :is(div:not(.flourish-embed)) {
    @apply !p-8;
  }

  .article-body > :is(div:not(.flourish-embed)) li {
    @apply my-6;
  }

  .article-body div.flourish-embed {
    @apply w-auto mx-auto;
  }

  .article-body iframe {
    @apply w-full border-0 outline-0;
  }

  .article-body a {
    @apply text-accent-600;
  }

  .article-body > :is(p, ul, ol) {
    @apply my-3 font-light;
  }

  .article-body h1 {
  }

  .article-body h2 {
    @apply text-3xl mt-5 mb-2.5 font-medium;
  }

  .article-body h3 {
    @apply text-2xl mt-5 mb-2.5 font-medium;
  }

  .article-body h4 {
    @apply text-[1.4rem] my-2.5 font-medium;
  }

  .article-body h5 {
  }

  .article-body h6 {
    @apply text-xs my-2.5;
  }

  .article-body ol {
    @apply list-decimal px-8;
  }

  .article-body ul {
    @apply list-disc px-8;
  }

  .article-body li {
    @apply my-3;
  }

  .article-body li > p {
    @apply inline;
  }

  .article-body img {
  }

  /* Article Body V3 styles */
  .report-platformisation {
    @apply font-sans text-sm font-light;
  }

  .report-platformisation > section,
  .report-platformisation-wrapper:not(:has(.report-platformisation > section)) {
    @apply dark:bg-[#0E0F10] border-[0.5px] dark:border-gray-600 p-5;
  }

  .report-platformisation > section:first-child {
    @apply border-t-0;
  }

  .report-platformisation > section:last-child {
    @apply border-b-0;
  }

  .report-platformisation-wrapper .paywall-fade {
    @apply absolute bottom-0 right-0 left-0 h-80 bg-gradient-to-b from-transparent to-stears_white dark:to-stears_black;
  }

  .report-platformisation-wrapper .paywall {
    @apply max-w-[740px] mx-auto font-jakarta;
  }

  /* Style for report v3 content */
  .report-platformisation-wrapper:has(.report-platformisation > section)
    .paywall-fade {
    @apply mx-auto;
  }

  .report-platformisation-wrapper:has(.report-platformisation > section)
    .paywall {
    @apply mx-auto;
  }

  .report-platformisation-wrapper:has(.report-platformisation > section)
    > header.article-header {
    @apply space-y-5 pt-5 px-5 border-t-[0.5px] border-x-[0.5px] dark:border-gray-600 dark:bg-[#0E0F10];
  }

  .report-platformisation-wrapper:has(.report-platformisation > section)
    footer.article-footer {
    @apply p-5 border-b-[0.5px] border-x-[0.5px] dark:border-gray-600 dark:bg-[#0E0F10];
  }

  .report-platformisation > section div.callout {
    @apply border-l-2 p-5 text-xl md:text-[28px] leading-9 font-sans;
  }

  .report-platformisation > section div.callout:before {
    content: "“";
  }

  .report-platformisation > section div.callout:after {
    content: "”";
  }

  .report-platformisation
    > section
    > :is(p, h1, h2, h3, h4, h5, h6, ul, ol, figure, div:not(.flourish-embed)) {
    @apply max-w-[700px] mx-auto;
  }

  .report-platformisation > :is(div:not(.flourish-embed, .callout)) {
    @apply !p-5 dark:!bg-black mb-4 !border-[#4B5563] !border-[0.5px];
  }

  .report-platformisation div.flourish-embed {
    @apply !px-5;
  }

  .report-platformisation > :is(div:not(.flourish-embed, .callout)) li {
    @apply my-1;
  }

  .report-platformisation div.flourish-embed {
    @apply w-auto -mx-5;
  }

  .report-platformisation iframe {
    @apply !w-full border-0 outline-0 my-4;
  }

  .report-platformisation a {
    @apply hover:underline;
  }

  .report-platformisation a[href^="http"]::after {
    display: none;
  }

  .report-platformisation :is(p, ul, ol) {
    @apply my-3 leading-6;
  }

  .report-platformisation :is(p) {
    @apply md:text-justify;
  }

  .report-platformisation h1 {
  }

  .report-platformisation h2 {
    @apply text-xl mt-5 mb-2.5 font-normal;
  }

  .report-platformisation h3 {
    @apply text-xl mt-5 mb-2.5 font-normal;
  }

  .report-platformisation h4 {
    @apply text-xl my-2.5 font-normal;
  }

  .report-platformisation h5 {
  }

  .report-platformisation h6 {
    @apply text-xs my-2.5;
  }

  .report-platformisation ol {
    @apply list-decimal px-8;
  }

  .report-platformisation ul {
    @apply list-disc px-8;
  }

  .report-platformisation li {
    @apply my-3;
  }

  .report-platformisation li > p {
    @apply inline;
  }

  .report-platformisation img {
  }

  /* Style for report v2 content */
  .report-platformisation-wrapper:not(:has(.report-platformisation > section))
    .paywall-fade {
    @apply -mx-5;
  }

  .report-platformisation-wrapper:not(:has(.report-platformisation > section))
    .paywall {
    @apply -mx-5;
  }

  .report-platformisation-wrapper:not(:has(.report-platformisation > section)) {
    @apply dark:bg-[#0E0F10] border-[0.5px] dark:border-gray-600 p-5;
  }

  .report-platformisation-wrapper:not(:has(.report-platformisation > section))
    > header.article-header {
    @apply space-y-5 dark:border-gray-600 dark:bg-[#0E0F10] mb-5;
  }

  .report-platformisation-wrapper:not(:has(.report-platformisation > section))
    #article-finish-node {
    @apply -mx-5;
  }

  .report-platformisation-wrapper:not(:has(.report-platformisation > section))
    footer.article-footer {
    @apply pt-5 dark:bg-[#0E0F10];
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.leaflet-popup-close-button,
.leaflet-popup-tip-container {
  display: none;
  border-radius: 0 !important;
}
.leaflet-popup-content {
  margin: 0;
  width: auto !important;
  box-shadow: none;
  border: none;
  background-color: transparent;
}
.leaflet-popup-content p {
  margin-top: auto;
  margin-bottom: auto;
}
.leaflet-popup-content-wrapper {
  box-shadow: none;
  background-color: transparent;
}

/*Todo: add in a utils class*/
/*Chrome and others*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*Firefox*/

input[type="number"] {
  -moz-appearance: textfield;
}
